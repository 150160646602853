import React, { useEffect, useState } from "react";
import { images, toastError } from "../../../utils/constants";
import "./PollResults.css";
import { getMeetingPollSlots, sendCalendarInvite } from "../../../apis/calendars";
import moment from "moment";
import { Spinner } from "reactstrap";

export default function PollResults({ handleNavigation, selectedCalendar }) {
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(null);

  useEffect(() => {
    if (selectedCalendar) {
      setLoading(true);
      getMeetingPollSlots(selectedCalendar.id)
        .then((res) => {
          const sortedData = res.data.sort((prev,curr) => curr.meetingPollSlotVotes.length - prev.meetingPollSlotVotes.length)
          setResults(sortedData);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toastError(err.response?.data?.message, "top-right");
        });
    }
  }, [selectedCalendar]);


  const sendCalendarInviteHandler = (slotId,calendarId) => {
    const payload = {
        slotId,
        calendarId
    }
    setInviteLoading(slotId);
    sendCalendarInvite(payload).then(() => {
      setInviteLoading(null);
    }).catch(err => {
      setInviteLoading(null);
      toastError(err.response?.data?.message, "top-right");
    });
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <div className="flex-grow-1">
          <div className="pb-3">
            <div
              className="back-button"
              onClick={() =>
                handleNavigation(
                  "2",
                  "left",
                  `/calendar/${selectedCalendar.name}`
                )
              }
            >
              <img src={images.backIcon} alt="icon" />
              <label className="calendar-header">Calendar</label>
            </div>
            <p className="calendar-title">Results</p>
          </div>
          {results.length ? (
            results.map((result) => {
              return (
                <div key={result.id} className="slot-card mb-4 bg-light p-3 rounded-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex">
                    <img
                      className="appointment-icon me-2"
                      src={images.appointmentIcon}
                      alt="appointment"
                    />
                    <label className="slot-label">{`${moment(
                      result.from
                    ).format("DD MMMM YYYY")} : ${moment(result.from).format(
                      "hh:mm a"
                    )} - ${moment(result.to).format("hh:mm a")}`}</label>
                  </div>
                  <button
                    type="button"
                    className="btn-invite"
                    onClick={() => sendCalendarInviteHandler(result.id,result.calendarId)}
                  >
                    {inviteLoading === result.id ? <Spinner size="sm" color="primary" /> : "Send invite"}
                  </button>
                  </div>
                  <div className="d-flex mb-2">
                    <img
                      className="vote-icon me-2"
                      src={images.votesIcon}
                      alt="votes"
                    />
                    <label className="vote-label">{`${result.meetingPollSlotVotes.length} Votes`}</label>
                  </div>
                  {result.meetingPollSlotVotes.map((slot) => (
                    <div className="user-card d-flex mb-2">
                      <img
                        className="vote-icon me-2"
                        src={images.userIcon}
                        alt="votes"
                      />
                      <p
                        key={slot.meetingPollSlotId}
                        className="user-label m-0"
                      >{`${slot.name} (${slot.email})`}</p>
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <p>No poll results found</p>
          )}
        </div>
      )}
    </div>
  );
}
