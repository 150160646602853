import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input,} from 'reactstrap';
import {images} from '../../../utils/constants';
import "./PublicPageSidebar.css";

export default function PublicPageSidebar({
                                            publicCalendar,
                                            timeZones,
                                            setSelectedTimeZone,
                                            selectedTimeZone,
                                            setSearchTimezone,
                                            searchTimezone
                                          }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  return (
    <>
      <h1 className="calendar-main-title public-main-title mb-3">{publicCalendar?.name}</h1>
      <div className='public-sidebar-container mb-4 py-1 ps-3'>
        <div className="public-sidebar-item">
          <div className='public-sidebar-icon icon-container'>
            <img src={"https://app.wecal.co/assets/timer.svg"} alt="timer_icon"/>
          </div>
          <h6 className="public-sidebar-title mb-0">{publicCalendar?.duration} minutes</h6>
        </div>
        <div className="public-sidebar-item">
          <div className='public-sidebar-icon icon-container'>
            <img src={"https://app.wecal.co/assets/earth.svg"} alt="timer_icon"/>
          </div>
          <Dropdown className="public-sidebar-dropdown" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="public-sidebar-title mb-0">
              <span className='me-3'>{selectedTimeZone}</span>
              <img src={"https://app.wecal.co/assets/dropdown-gray.svg"} alt="timer_icon"/>
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-scroll'>
              <Input
                type="text"
                className='txt-duration mb-0'
                placeholder="Search Timezone"
                onChange={(e) => setSearchTimezone(e.target.value)}
                value={searchTimezone}
              />
              {timeZones.map(a => {
                return (
                  <DropdownItem onClick={() => {
                    setSelectedTimeZone(a);
                    localStorage.setItem('timezone', a);
                  }}>{a}</DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        {publicCalendar?.description &&
        <div className="public-sidebar-item">
          <div className='public-sidebar-icon icon-container'>
            <img src={images.descriptionIcon} alt="timer_icon"/>
          </div>
          <h6 className="public-sidebar-description mb-0">
            {publicCalendar.description}
          </h6>
        </div>}
        <div className="public-sidebar-item border-0">
          <div className='public-sidebar-icon icon-container'>
            <img src={"https://app.wecal.co/assets/physical-meeting.svg"} alt="timer_icon"/>
          </div>
          {publicCalendar?.location?.includes('.zoom.us/') ?
            <p className="public-sidebar-title mb-0">Zoom Video Conferencing - the Zoom Desktop Client can be
              downloaded{' '}
              <a href="https://zoom.us/download">at this link.</a></p> :
            <p className="public-sidebar-title mb-0">{publicCalendar?.location}</p>}
        </div>
      </div>
    </>
  )
}
