import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import "./CalendarDetails.css";
import { images, toastError, toastSuccess } from "../../../utils/constants";
import {
  addMeetingPollSlot,
  deleteMeetingPollSlot,
  getMeetingPollSlots,
  updateCalendar,
} from "../../../apis/calendars";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { getElements } from "../../../apis/element";
import TimePicker from "react-time-picker-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker-input/dist/components/TimeInput.css";
import moment from "moment";
export default function CalendarDetailsMeetingPoll({
  isSmallScreen,
  isLoading: loading,
  calendarLink,
  handleNavigation,
  setCalendarList,
  setSelectedCalendar,
  selectedCalendar,
  calendars,
  zoomEnabled,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [linkCopied, setLinkCopied] = useState("Copy");
  const [calendarDetail, setCalendarDetail] = useState(selectedCalendar);
  const [location, setLocation] = useState([{ id: "1", name: "Google Meet" }]);
  const formikRef = useRef();
  const [slots, setSlots] = useState([]);
  const [initialSlots, setInitialSlots] = useState([]);
  const [deletedSlotsIds, setDeleteSlotsIds] = useState([]);
  const [getSlotLoading, setGetSlotLoading] = useState(false);
  const [slotError, setSlotError] = useState("");

  useEffect(() => {
    setCalendarDetail(selectedCalendar);
    getElements("location").then((res) => {
      const locationList = zoomEnabled
        ? [
            { id: "1", name: "Google Meet" },
            { id: "2", name: "Zoom Meet" },
          ]
        : [{ id: "1", name: "Google Meet" }];
      setLocation([...locationList, ...res.data]);
    });
    setLinkCopied("Copy");
  }, [selectedCalendar, zoomEnabled]);

  const getSlotsHandler = () => {
    setGetSlotLoading(true);
    getMeetingPollSlots(selectedCalendar.id)
      .then((res) => {
        setGetSlotLoading(false);
        setSlots(res.data);
        setInitialSlots(res.data);
      })
      .catch((err) => {
        setGetSlotLoading(false);
        toastError(err.response?.data?.message, "top-right");
      });
  };

  useEffect(() => {
    if (selectedCalendar) getSlotsHandler();
  }, [selectedCalendar]);

  const copyLinkToClipboard = () => {
    const domain = window.location.host;
    navigator.clipboard
      .writeText(`${domain}/public${calendarLink}/${selectedCalendar.link}`)
      .then(() => setLinkCopied("Copied!"));
  };

  const toggleDropdown = () => {
    if (isEditable) setDropdownOpen(!dropdownOpen);
    else setEditable(true);
  };

  const handleCancelUpdate = (resetForm) => {
    resetForm();
    setSlots(initialSlots);
    setEditable(false);
  };

  const handleMeetingDetails = (values) => {
    values.link = values.link.split(" ").join("-");
    const newValues = Object.keys(values).reduce((p, key) => {
      if (values[key] !== "" && values[key] !== selectedCalendar[key])
        return { ...p, [key]: values[key] };
      else return p;
    }, {});
    const isSlotsChanged = initialSlots.length !== slots.length || slots.find((slot) => {
     return initialSlots.every((s) => {
      return moment(slot.from).diff(moment(s.from)) || moment(slot.to).diff(moment(s.to))
    })
    });
    if(Object.keys(newValues).length) updateMeetingCalendar({ ...newValues });
    if(isSlotsChanged) saveMeetingPollSlotsHandler(values.duration);
  };
  
  const saveMeetingPollSlotsHandler = (duration) => {
    const formateSlots = slots.map((slot) => {
      if (slot?.createdAt) return { ...slot, meetingPollSlotId: slot.id, to: moment(slot.from).add(duration,"minute").toISOString() };
      return {...slot,to: moment(slot.from).add(duration,"minute").toISOString()};
    });
    const isSlotDuplicate = formateSlots.find((slot) => formateSlots.filter(s => moment(s.from).isSameOrAfter(slot.from) && moment(s.from).isBefore(slot.to)).length > 1);
    if(isSlotDuplicate){
      setSlotError("This slot is already taken on the calendar(s)");
      return
    }
    setIsLoading(true);
    const payload = {
      calendarId: selectedCalendar.id,
      slots:formateSlots
    }
    addMeetingPollSlot(payload).then((res) => {
      if (deletedSlotsIds.length) {
        deleteMeetingPollSlot({ ids: deletedSlotsIds })
          .then((res) => {
            getSlotsHandler();
            setEditable(!isEditable);
            toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toastError(err.response?.data?.message, "top-right");
          });
      } else {
        setIsLoading(false);
        getSlotsHandler();
        setEditable(!isEditable);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
      }
    }).catch((err) => {
      setIsLoading(false);
      if(err.response?.data?.message.includes("Slots")) {
        setSlotError(err.response?.data?.message);
      } else toastError(err.response?.data?.message, "top-right");
    });
  };

  const updateMeetingCalendar = (updateData) => {
    setIsLoading(true);
    updateCalendar(selectedCalendar.id, updateData)
      .then(() => {
        const data = Object.assign([], calendars);
        const calendarIndex = data.findIndex(
          (cal) => cal.id === selectedCalendar.id
        );
        Object.assign(data[calendarIndex], updateData);
        setCalendarList(data);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        setSelectedCalendar(data[calendarIndex]);
        setEditable(!isEditable);
        setIsLoading(false);
      })
      .catch((err) => {
        formikRef.current.setFieldError("link", err.response.data.message);
        formikRef.current.setFieldError("slot", err.response.data.message);
        setIsLoading(false);
      });
  };

  const handleDateChange = (date, slotId) => {
    setSlotError("");
    const list = Object.assign([], slots);
    const index = list.findIndex((l) => l.id === slotId);
    const from = moment(
      moment(date).format("DD/MM/YYYY") +
        " " +
        moment(list[index]["from"]).format("HH:mm"),
      "DD/MM/YYYY HH:mm"
    ).toISOString();
    list[index] = { ...list[index], from};
    setSlots(list);
    setEditable(true);
  };

  const handleSlotChange = (time, slot, key) => {
    if (time[key] === moment(slot[key]).format("HH:mm")) return;
    setSlotError("");
    const list = Object.assign([], slots);
    const index = list.findIndex((l) => l.id === slot.id);
    const date = moment(list[index][key]).format("DD/MM/YYYY");
    const newDate = moment(
      date + " " + time[key],
      "DD/MM/YYYY HH:mm"
    ).toISOString();
    list[index] = { ...list[index], [key]: newDate };
    setSlots(list);
    setEditable(true);
  };

  const handleAddSlot = (e) => {
    e.preventDefault();
    const today = new Date();
    const defaultSlot = {
      id: slots.length ? Math.max(...slots.map((slot) => slot.id)) + 1 : 1,
      from: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        "09",
        "00"
      ).toISOString()
    };
    setSlots((prev) => [...prev, defaultSlot]);
    setEditable(true);
  };

  const handleRemoveSlot = (slotId, isExist) => {
    setSlotError("");
    const list = Object.assign([], slots);
    const index = list.findIndex((l) => l.id === slotId);
    list.splice(index, 1);
    setSlots(list);
    if (isExist) setDeleteSlotsIds([...deletedSlotsIds, slotId]);
    setEditable(true);
  };

  return (
    <>
      {loading || getSlotLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <Formik
          initialValues={{
            description: calendarDetail?.description || "",
            duration: calendarDetail?.duration || "",
            location: calendarDetail?.location || "",
            name: calendarDetail?.name || "",
            link: calendarDetail?.link || "",
            bookingEnabled: calendarDetail?.bookingEnabled || false,
          }}
          innerRef={formikRef}
          enableReinitialize={true}
          onSubmit={(values) => handleMeetingDetails(values)}
          validationSchema={validations}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            resetForm,
            setFieldError,
          }) => (
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className={`meeting-detail-content ${isEditable ? "" : "h-100" }`}>
                <div className="calendar-main-title-container">
                  <div
                    className="back-button"
                    onClick={() => handleNavigation("2", "left")}
                  >
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Calendar details</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h1 className="calendar-main-title">Meeting details</h1>
                  </div>
                </div>
                <FormGroup className="mb-0">
                  <h6 className="meeting-label">Name</h6>
                  <Input
                    onClick={() => setEditable(true)}
                    readOnly={!isEditable}
                    className="txt-calendar-name"
                    type="text"
                    name="name"
                    placeholder="Enter Calendar name"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="formik-error mb-0">
                    {errors.name && touched.name && errors.name}
                  </p>
                </FormGroup>

                <h6 className="meeting-label">Link</h6>
                <div className="calendar-link-component">
                  <div
                    className="main-calendar-link-container me-1 d-flex"
                    onClick={() => setEditable(true)}
                  >
                    <span>/</span>
                    <Input
                      onFocus={() => setEditable(true)}
                      className="txt-calendar-link mb-0"
                      type="text"
                      name="link"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.link}
                      placeholder="Enter calendar link"
                    />
                  </div>
                  <div className="d-flex">
                    <div
                      className="btn-copy-calendar-link me-1"
                      onClick={copyLinkToClipboard}
                    >
                      {linkCopied}
                    </div>
                    <NavLink
                      to={`/public${calendarLink}/${selectedCalendar?.link}`}
                      className="btn-open-link"
                      target="_blank"
                    >
                      ↗
                    </NavLink>
                  </div>
                </div>
                <p className="formik-error mb-3">
                  {errors.link && touched.link && errors.link}
                </p>

                <h6 className="meeting-label">Description</h6>
                <Input
                  type="textarea"
                  onClick={() => setEditable(true)}
                  readOnly={!isEditable}
                  name="description"
                  className="description-textarea"
                  placeholder="Please insert here an address or location information"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                />
                <p className="formik-error">
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </p>

                <h6 className="meeting-label">Duration</h6>
                <div
                  onClick={() => setEditable(true)}
                  className="duration-container d-flex ps-2 p align-items-center"
                >
                  <label>Minutes</label>
                  <Input
                    type="text"
                    readOnly={!isEditable}
                    name="duration"
                    className="txt-duration mb-0"
                    placeholder="15"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.duration}
                  />
                </div>
                <p className="formik-error mb-3">
                  {errors.duration && touched.duration && errors.duration}
                </p>
                <h6 className="meeting-label">Location</h6>
                <Dropdown
                  className="duration-dropdown mb-3"
                  isOpen={dropdownOpen && isEditable}
                  toggle={toggleDropdown}
                >
                  <DropdownToggle
                    className="d-flex justify-content-between ps-2"
                    tag="div"
                  >
                    <span>{values.location}</span>
                    <div className="dropdown-icon-container">
                      <img
                        className="dropdown-icon"
                        src={images.dropdownIcon}
                        alt="icon"
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {location.map((location) => (
                      <DropdownItem
                        key={location.id}
                        onClick={() => setFieldValue("location", location.name)}
                      >
                        {location.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <p className="formik-error">
                  {errors.location && touched.location && errors.location}
                </p>

                <div>
                  <h6 className="meeting-label">Slots</h6>
                  {slots.length ? (<div>
                    {slots.map((slot) => {
                      return (
                        <div key={slot.id} className="d-flex align-items-center mb-2">
                          <div className="meeting-poll-time-box-container">
                            <div className="d-flex align-items-center">
                              <h6 className="meeting-label m-0 text-nowrap">Slot date </h6>
                              <DatePicker
                                selected={
                                  new Date(
                                    moment(slot.from).format("YYYY-MM-DD")
                                  )
                                }
                                onChange={(date) =>
                                  handleDateChange(date, slot.id)
                                }
                              />
                            </div>
                            <div className="d-flex flex-wrap">
                              <div className="meeting-poll-time-box">
                                <h6 className="meeting-label m-0 me-2 text-nowrap">
                                  Slot time 
                                </h6>
                                <TimePicker
                                  className="bg-light"
                                  onChange={(from) =>
                                    handleSlotChange({ from }, slot, "from")
                                  }
                                  allowDelete
                                  value={moment(slot.from).format("HH:mm")}
                                />
                              </div>
                            </div>
                          </div>
                          <img
                            alt="close"
                            className="btn-close-icon ms-3"
                            src={images.closeIcon}
                            onClick={() =>
                              handleRemoveSlot(slot.id, slot?.createdAt)
                            }
                          />
                        </div>
                      );
                    })}
                    {slotError && <p className="formik-error">{slotError}</p>}
                    </div>) : (
                    <p>No slots are added, Please add slots</p>
                  )}
                </div>
                <div className="mt-3">
                  <a
                    className="btn-add-slot"
                    href=""
                    onClick={(e) => handleAddSlot(e)}
                  >
                    Add Time Slot
                  </a>
                </div>
              </div>
              {isEditable && (
                <div className="editable-button-container">
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => handleCancelUpdate(resetForm)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-save"
                    onClick={handleSubmit}
                  >
                    {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
                  </button>
                </div>
              )}
            </div>
          )}
        </Formik>
      )}
    </>
  );
}
const validations = Yup.object().shape({
  link: Yup.string()
    .required("Link is required")
    .matches(
      /^[a-zA-Z0-9 -]+$/,
      "You are trying to use characters not compatible with a URL"
    ),
  duration: Yup.number()
    .typeError("Only numbers are allowed!")
    .min(5, "Duration is too sort!")
    .max(360, "Duration is too long!"),
});
