import React, { useEffect, useState } from "react";
import "./SelectCalendarComponent.css";
import { images, toastError } from "../../../utils/constants";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { removeCalendar } from "../../../apis/calendars";
import { useLocation } from "react-router-dom";

export default function SelectCalendarComponent({
  getCalendarsHandler,
  handleNavigation,
  calendarLink,
  isSmallScreen,
  selectedCalendar,
  setCalendarList,
  calendarList,
}) {
  const [isOpenModel, setOpenModel] = useState(false);
  const [selected, setSelected] = useState("");
  const { pathname } = useLocation();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    const path = pathname.split("/");
    if (path[1] === "calendar") setSelected(path[3] || "meetingDetails");
  }, [pathname]);

  const handleClick = () => {
    window.open(`/public${calendarLink}/${selectedCalendar?.link}`);
  };

  const handleDeleteCalendar = async () => {
    setIsDeleteLoading(true);
    const { id } = selectedCalendar;
    removeCalendar(id)
      .then(() => {
        const data = calendarList.filter((cal) => cal.id !== id);
        setCalendarList(data);
        toggleModalHandler();
        getCalendarsHandler();
        setIsDeleteLoading(false);
        handleNavigation("1", "left", "/");
      })
      .catch((err) => {
        setIsDeleteLoading(false);
        toastError(err.response.data.message, "top-right");
      });
  };

  const toggleModalHandler = () => {
    setOpenModel(!isOpenModel);
  };

  return (
    <div className="calendar-details-container">
      <Modal
        modalClassName="delete-modal-small-screen"
        className="delete-modal-small-screen"
        isOpen={isSmallScreen && isOpenModel}
      >
        <div className="delete-modal-item">
          <div className="delete-modal-content">
            <label className="modal-title">Delete Calendar</label>
            <p className="modal-details text-center mb-0">
              Do you want to delete this calendar?
            </p>
          </div>
          <button
            className="btn-delete-calendar"
            onClick={handleDeleteCalendar}
          >
            Delete Calendar
          </button>
        </div>
        <button className="btn-cancel-calendar" onClick={toggleModalHandler}>
          Cancel
        </button>
      </Modal>
      <div>
        <div className="calendar-main-title-container">
          <div
            className="back-button"
            onClick={() => handleNavigation("1", "right", "/")}
          >
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Home</label>
          </div>
          <h1 className="calendar-main-title">{selectedCalendar?.name}</h1>
        </div>

        {selectedCalendar?.type === 'meeting-poll' ? 
         <div className="calendar-item-container mb-3">
         <div
           onClick={() =>
             handleNavigation(
               "3",
               "left",
               `/calendar/${selectedCalendar?.name}`
             )
           }
           className={`calendar-details-item ${
             selected === "meetingDetails" ? "selected" : ""
           }`}
         >
           <div className="calendar-details-icon icon-container">
             <img src={images.bookingIcon} alt=".." />
           </div>
           <div className="calendar-details-title-container">
             <h6 className="mb-0 text-nowrap title">Meeting details</h6>
             <div className="direction-right">
               <img src={images.angleRightIcon} alt="icon" />
             </div>
           </div>
         </div>
         <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/participants`
              )
            }
            className={`calendar-details-item ${
              selected === "participants" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.participatesIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Participants</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>
         <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/results`
              )
            }
            className={`calendar-details-item ${
              selected === "results" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.topIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Results</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>
         <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/placeholder`
              )
            }
            className={`calendar-details-item border-0 ${
              selected === "placeholder" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.stopIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Placeholder</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>
         </div> : <>
        <div className="calendar-item-container mb-3">
          <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}`
              )
            }
            className={`calendar-details-item ${
              selected === "meetingDetails" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.bookingIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Meeting details</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/availabilitydetails`
              )
            }
            className={`calendar-details-item ${
              selected === "availabilitydetails" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.availabilityIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Availability</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/participants`
              )
            }
            className={`calendar-details-item ${
              selected === "participants" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.participatesIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Participants</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/share`
              )
            }
            className={`calendar-details-item ${
              selected === "share" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.chainIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Share</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              handleNavigation(
                "3",
                "left",
                `/calendar/${selectedCalendar?.name}/theme`
              )
            }
            className={`calendar-details-item border-0 ${
              selected === "theme" ? "selected" : ""
            }`}
          >
            <div className="calendar-details-icon icon-container">
              <img src={images.paletteIcon} alt=".." />
            </div>
            <div className="calendar-details-title-container">
              <h6 className="mb-0 text-nowrap title">Calendar Theme</h6>
              <div className="direction-right">
                <img src={images.angleRightIcon} alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <label className="coming-soon-title mb-1">Coming soon</label>
        <div className="coming-soon-container ps-3">
          <div className="coming-soon-item ">
            <div className="icon-container">
              <img src={images.workflowsIcon} alt="icon" />
            </div>
            <div className="coming-soon-title-container border-0">
              <h6 className="title mb-0">Workflows</h6>
            </div>
          </div>
        </div>
        </>}
      </div>
      <div className="d-flex flex-column align-items-center">
        {!isSmallScreen && isOpenModel && (
          <div className="delete-calendar-modal" onClick={toggleModalHandler}>
            <ModalHeader className="p-0 border-0">Delete Calendar</ModalHeader>
            <ModalBody className="p-0 text-center">
              Are you sure, do you want to delete this calendar?
            </ModalBody>
            <div className="pt-3">
              <Button
                color="light"
                className="delete-calendar-btn-no me-1"
                onClick={toggleModalHandler}
              >
                No
              </Button>
              <Button
                color="danger"
                className="delete-calendar-btn-yes ms-1"
                onClick={handleDeleteCalendar}
              >
                Yes
              </Button>
            </div>
          </div>
        )}
        <div className="btn-delete-container d-flex justify-content-center align-items-center">
          <button
            className="btn-delete-availability"
            onClick={toggleModalHandler}
          >
            {isDeleteLoading ? (
              <Spinner size={"sm"} color="primary" />
            ) : (
              "Delete Calendar"
            )}
          </button>
        </div>
        <div className="btn-delete-container d-flex justify-content-center align-items-center">
          <button className="button-preview-calendar" onClick={handleClick}>
            Live booking page ↗
          </button>
        </div>
      </div>
    </div>
  );
}
