import React, { useEffect, useState } from "react"
import { Route, Router, Switch, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import './App.css';
import { Offline, Online } from "react-detect-offline";
import HomePage from "./Container/Home/HomePage";
import { useMediaQuery } from "react-responsive";
import SignIn from "./Components/SignIn/SignIn";
import SetURL from "./Components/SetURL/SetURL";
import { ToastContainer } from "react-toastify";
import PublicPage from "./Container/Public/PublicPage";
import SplashScreen from "./Components/SplashScreen/SplashScreen";
import { getVersion } from "./apis/auth";
import { toastError } from "./utils/constants";
import { appVersion } from "./calendarConfig";
import { unregister } from "./registerServiceWorker";
import axios from "axios";

axios.interceptors.response.use((res) => res, error => {
  const extra = {};
  if (error.response) {
    extra.response = error.response.data;
    extra.status = error.response.status;
  }
  if (error.config) {
    extra.payload = error.config.data;
    extra.url = error.config.url;
  }
  Sentry.captureException(error,{extra});
  return Promise.reject(error);
});
function App() {
  const history = useHistory();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 991px)" });
  const [userLogin, setUserLogin] = useState(null);
  const [showPanel, setShowPanel] = useState("1");
  const [slideDirection, setSlideDirection] = useState("right");
  const [version, setVersion] = useState("");
  const [isRefreshTokenExpired, setIsRefreshTokenExpired] = useState(false);
  const handleWebNavigation = (pathname) => {
    if (isSmallScreen) {
      const path = pathname.split("/");
      if (path[1] !== 'profiledetails' && path[1] !== 'availability' && path[1] !== 'team' && path[1] !== 'calendar') {
        if (pathname === "/") {
          setSlideDirection("right")
          setShowPanel("1");
        } else if (path.length === 2) {
          setShowPanel("2");
        } else {
          setSlideDirection("left")
          setShowPanel("3");
        }
      }
    }
  }

  useEffect(() => {
    userLogin ? Sentry.setUser({ email: userLogin.email }) : Sentry.setUser(null)
  }, [userLogin])

  const handleNavigation = (panel, direction, path) => {
    if (panel) setShowPanel(panel);
    if (direction) setSlideDirection(direction);
    if (path) history.push(path);
  }

  const clearCacheData = async () => {
    if ('caches' in window) {
      await caches.keys().then((names) => Promise.all(names.map(name => caches.delete(name))));
      await unregister()
      window.location.reload(true);
    }
  };

  useEffect(() => {
    getVersion().then(version => {
      setVersion(version);
    }).catch(err => {
      toastError(err.response?.data?.errors, "bottom-right")
    });
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={() => <SplashScreen reload={true} message="- Something went wrong ! Please try again..." />}>
      <div className="body">
        <ToastContainer />
        <Offline polling={{ interval: 10000 }}>
          <SplashScreen message="- you are offline" />
        </Offline>
        <Online polling={{ interval: 10000 }}>
          <Router history={history}>
            <Switch>
              <Route path="/public" render={(props) =>
                <PublicPage
                  slideDirection={slideDirection}
                  handleNavigation={handleNavigation}
                  showPanel={showPanel}
                  isSmallScreen={isSmallScreen}
                  {...props} />}
              />

              <Route path="/signin" exact render={(props) =>
                <SignIn
                  {...props}
                  userLogin={userLogin}
                  setIsRefreshTokenExpired={setIsRefreshTokenExpired}
                  setUserLogin={setUserLogin} />}
              />

              {userLogin && (!userLogin?.link || !userLogin?.defaultLocation) &&
                <Route path="/seturl" render={(props) =>
                  <SetURL
                    {...props}
                    userLogin={userLogin}
                    isSmallScreen={isSmallScreen}
                    handleNavigation={handleNavigation}
                    setUserLogin={setUserLogin} />}
                />}

              <Route path="/" render={(props) =>
                <HomePage
                  slideDirection={slideDirection}
                  handleNavigation={handleNavigation}
                  showPanel={showPanel}
                  userLogin={userLogin}
                  isSmallScreen={isSmallScreen}
                  setUserLogin={setUserLogin}
                  handleWebNavigation={handleWebNavigation}
                  isRefreshTokenExpired={isRefreshTokenExpired}
                  setIsRefreshTokenExpired={setIsRefreshTokenExpired}
                  {...props} />} />
            </Switch>
          </Router>
        </Online>
        {version && version !== appVersion &&
          <button className="btn-restart" onClick={() => clearCacheData()}>
            New Version is available. Update Now
          </button>
        }
      </div>
    </Sentry.ErrorBoundary >
  );
}

export default App;
