export const BASE_URL = process.env.REACT_APP_API_URL;
export const URLS = {
  GOOGLE_AUTH: BASE_URL + '/authentication/google/token',
  ZOOM_INTEGRATION: BASE_URL + '/authentication/integrations/zoom',
  CALENDARS: BASE_URL + '/calendars',
  ELEMENT: BASE_URL + '/elements',
  UPDATE_USER: BASE_URL + '/users/update',
  PUBLIC_CALENDAR: BASE_URL + '/calendars/getPublicCalendar',
  PUBLIC_CALENDAR_SLOTS: BASE_URL + '/calendars/slots',
  PUBLIC_CALENDAR_EVENT: BASE_URL + '/calendars/events',
  AVAILABILITY: BASE_URL + '/availability',
  MEETING_POLL: BASE_URL + '/meetingPollSlots',
  ADD_PLACEHOLDERS: BASE_URL + '/meetingPollSlots/placeholders/add',
  REMOVE_PLACEHOLDERS: BASE_URL + '/meetingPollSlots/placeholders/remove',
  MEETING_POLL_PUBLIC: BASE_URL + '/meetingPollSlots/public',
  MEETING_POLL_VOTE: BASE_URL + '/meetingPollSlots/vote',
  SEND_INVITE: BASE_URL + '/meetingPollSlots/calendarInvite',
  TEAM: BASE_URL + '/teams',
  GET_TIMEZON: BASE_URL + '/calendars/timezones',
  SUPPORT: BASE_URL + '/users/support-request',
  GET_VERSION: BASE_URL + '/version',
}
