export const CLIENT_ID = "80464504005-is9hs5k58cfqtmt4risftv2fl8995p6o.apps.googleusercontent.com";
export const SCOPES = "https://www.googleapis.com/auth/calendar.calendars.readonly https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.freebusy  https://www.googleapis.com/auth/calendar.events.freebusy https://www.googleapis.com/auth/calendar.settings.readonly";
export const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
]


export const ZOOM_CLIENT_ID = "Vh5MKWFiRKWUQedeR2ktrg";
export const CLIENT_SECRET = "UVbf2hnNALUHCEFMi839jxnJjUwfg342";
export const appVersion = "1.0.6";
