import React from "react";
import { FormGroup, Input, Tooltip } from "reactstrap";

export default function InlineWidget({
  calendarWidgetURL,
  toggle,
  copyToClipBoard,
  copySuccess,
  backButtonSwitchHandler,
}) {
  const inlineEmbed = `<div class="root-widget" data-url="${calendarWidgetURL}" ></div>
    <link href="http://localhost:3000/widget/style.css" rel="stylesheet"> 
    <script type="text/javascript" src="http://localhost:3000/widget/script.js" async>
    </script>`;

  return (
    <>
      <div>
        <h2 className="calendar-title pb-3">Inline Embed</h2>
        <p>
          Place this code in your HTML where you want your Calendly widget to
          appear.
        </p>
        <FormGroup>
          <Input
            className="widget-description-textarea form-control"
            id="exampleText"
            name="text"
            type="textarea"
            value={inlineEmbed}
          />
        </FormGroup>
      </div>
      {toggle && (
        <div className="time-slot-button-container">
          <button className="btn-cancel" onClick={backButtonSwitchHandler}>
            Back
          </button>
          <Tooltip isOpen={copySuccess} target="TooltipExample">
            Copied!
          </Tooltip>
          <button
            className="btn-save"
            id="TooltipExample"
            onClick={() => copyToClipBoard(inlineEmbed)}
          >
            {copySuccess ? "Copied!" : "Copy Code"}
          </button>
        </div>
      )}
    </>
  );
}
