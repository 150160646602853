import axios from 'axios';
import { URLS } from "./URLS";

export const getCalendars = async () => {
  return axios.get(URLS.CALENDARS)
    .then(p => p.data);
}

export const getCalendar = async (id, token) => {
  return axios.get(`${URLS.CALENDARS}/${id}`, token)
    .then(p => p.data);
}

export const createCalender = async (data) => {
  return axios.post(URLS.CALENDARS, data)
    .then(p => p.data);
}

export const updateCalendar = async (id, data) => {
  return axios.put(`${URLS.CALENDARS}/${id}`, data).then(p => p.data);
}
export const removeCalendar = async (id, data) => {
  return axios.delete(`${URLS.CALENDARS}/${id}`).then(p => p.data);
}
export const getFreeSlots = async (id) => {
  return axios.get(`${URLS.CALENDARS}/${id}/slots`)
    .then(p => p.data);
}

export const getTimeZones = async (name) => {
  return axios.get(`${URLS.GET_TIMEZON}?name=${name}`)
    .then(p => p.data);
}

export const updateUser = async (data) => {
  return axios.put(URLS.UPDATE_USER, data)
    .then(p => p.data);
}
export const getMeetingPollSlots = async (calendarId) => {
  return axios.get(`${URLS.MEETING_POLL}/${calendarId}`)
    .then(p => p.data);
}
export const addMeetingPollSlot = async (data) => {
  return axios.post(URLS.MEETING_POLL, data)
    .then(p => p.data);
}
export const deleteMeetingPollSlot = async (data) => {
  return axios.delete(URLS.MEETING_POLL, {data})
    .then(p => p.data);
}
export const addMeetingPollVote = async (data) => {
  return axios.post(URLS.MEETING_POLL_VOTE, data)
    .then(p => p.data);
}

export const addPlaceholders = async (data) => {
  return axios.post(URLS.ADD_PLACEHOLDERS, data)
    .then(p => p.data);
}
export const removePlaceholders = async (data) => {
  return axios.post(URLS.REMOVE_PLACEHOLDERS, data)
    .then(p => p.data);
}

export const getPublicMeetingPollSlots = (id) => axios.get(`${URLS.MEETING_POLL_PUBLIC}/${id}`).then(p => p.data);

export const getPublicCalendar = (data) => axios.post(URLS.PUBLIC_CALENDAR, data).then(p => p.data);
export const getPublicCalendarSlots = (data) => axios.post(URLS.PUBLIC_CALENDAR_SLOTS, data).then(p => p.data);
export const createPublicCalendarEvent = (data) => axios.post(URLS.PUBLIC_CALENDAR_EVENT, data).then(p => p.data);

export const sendCalendarInvite = (data) => axios.post(URLS.SEND_INVITE, data).then(p => p.data);