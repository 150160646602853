import React, { useEffect, useState } from "react";
import { images, toastError, toastSuccess } from "../../../utils/constants";
import { Spinner } from "reactstrap";
import { addPlaceholders, removePlaceholders } from "../../../apis/calendars";

export default function Placeholder({ handleNavigation, selectedCalendar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const handleAddPlaceholder = () => {
    setIsLoading(true);
    addPlaceholders({calendarId:selectedCalendar.id}).then(res => {
      toastSuccess();
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
      toastError(err.response.data.message, "top-right");
    });
  };

  const handleRemovePlaceholder = () => {
    setIsRemoveLoading(true);
    removePlaceholders({calendarId:selectedCalendar.id}).then(res => {
      toastSuccess();
      setIsRemoveLoading(false);
    }).catch(err => {
      setIsRemoveLoading(false);
      toastError(err.response.data.message, "top-right");
    });
  };

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="flex-grow-1">
        <div className="pb-3">
          <div
            className="back-button"
            onClick={() =>
              handleNavigation(
                "2",
                "left",
                `/calendar/${selectedCalendar.name}`
              )
            }
          >
            <img src={images.backIcon} alt="icon" />
            <label className="calendar-header">Calendar</label>
          </div>
            <p className="calendar-title">Placeholders</p>
        </div>
          </div>
        <div className="editable-button-container">
          <button
            type="button"
            className="btn-cancel"
            onClick={() => handleRemovePlaceholder()}
          >
            {isRemoveLoading ? <Spinner size="sm" color="primary" /> : "Remove"}
          </button>
          <button
            type="button"
            className="btn-save"
            onClick={handleAddPlaceholder}
          >
            {isLoading ? <Spinner size="sm" color="primary" /> : "Add"}
          </button>
        </div>
    </div>
  );
}
