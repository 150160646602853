import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import moment from "moment";
import "./MeetingPollVote.css";
import { toastError, toastSuccess } from "../../../utils/constants";
import { createPublicCalendarEvent } from "../../../apis/calendars";
import { Formik } from "formik";
import * as Yup from "yup";
import { addMeetingPollVote } from "../../../apis/calendars";

const validations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export default function MeetingPollVote({
  handleNavigation,
  meetingPollSlots,
  setMeetingPollSlots
}) {
  const [value] = useState({ name: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef(null);

  const handleSaveMeetingPollVote = (values) => {
    const payload = meetingPollSlots.map((slot) => ({
      meetingPollSlotId: slot.id,
      email: values.email,
      name: values.name,
    }));
    setIsLoading(true);
    addMeetingPollVote(payload)
      .then(() => {
        formikRef.current.resetForm();
        setIsLoading(false);
        toastSuccess("Your vote has been registered");
        setMeetingPollSlots([]);
        handleNavigation("1", "right");
      })
      .catch((err) => {
        setIsLoading(false);
        toastError(err.response?.data.message, "top-right");
      });
  };

  const handleResetSlots = () => {
    handleNavigation("1", "right");
  };

  return (
    <Formik
      initialValues={{ name: value.name, email: value.email }}
      enableReinitialize={true}
      innerRef={formikRef}
      onSubmit={(values) => handleSaveMeetingPollVote(values)}
      validationSchema={validations}
    >
      {({
        values,
        handleSubmit,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
        <section className="get-user-info-component">
          <div>
            <div className="back-button" onClick={handleResetSlots}>
              <img
                src={"https://app.wecal.co/assets/angle-left.svg"}
                alt="back_icon"
              />
              <label className="calendar-header">Slots</label>
            </div>
            <h1 className="calendar-main-title mb-3">Add Meeting Poll Votes</h1>
            {meetingPollSlots.map((slot) => {
              return (
                <div
                  key={slot.id}
                  className="slot-item-container mb-3"
                >
                  <div className="slot-item">
                    <h6 className="slot-title ms-0 mb-0">
                      {moment(slot.from).format("HH:mm")}{" "}
                      - {moment(new Date(slot.to)).format("HH:mm")}
                    </h6>
                  </div>
                </div>
              );
            })}

            <FormGroup>
              <Label className="lbl-email" for="email">
                Your Name
              </Label>
              <Input
                className="txt-email"
                type="text"
                name="name"
                id="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                placeholder="Enter Your Name"
              />
              <p className="email-error text-danger mt-1">
                {errors.name && touched.name && errors.name}
              </p>
            </FormGroup>
            <FormGroup>
              <Label className="lbl-email" for="email">
                EMAIL
              </Label>
              <Input
                className="txt-email"
                type="email"
                name="email"
                id="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                placeholder="Enter email"
              />
              <p className="email-error text-danger mt-1">
                {errors.email && touched.email && errors.email}
              </p>
            </FormGroup>
          </div>
          <div className="public-page-save-container">
            <button className="btn-save" onClick={handleSubmit}>
              {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
            </button>
          </div>
        </section>
      )}
    </Formik>
  );
}
