import React, { useEffect, useRef, useState } from 'react'
import { FormGroup, Input, Label, Spinner } from 'reactstrap'
import moment from "moment";
import "./PublicPageEmailEvents.css"
import { toastError } from '../../../utils/constants';
import { createPublicCalendarEvent } from '../../../apis/calendars';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validations = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
});

export default function PublicPageEmailEvents({userLink,calendarLink, setBookedEvent,setIsConfirmedRequest, selectedEvent, handleNavigation, setSelectedEvent, setIsReqSlot, publicCalendar }) {
    const [value] = useState({ name: "", email: "" });
    const [isLoading, setIsLoading] = useState(false);
    const formikRef = useRef(null);
    const handleSaveEmail = (values) => {
        const data = {
            "userLink": userLink,
            "calendarLink": calendarLink,
            "email": values.email,
            "name": values.name,
            "startDateTime": selectedEvent.slot[0],
            "endDateTime": selectedEvent.slot[1]
        }
        setIsLoading(true);
        createPublicCalendarEvent(data)
            .then(() => {
                setIsReqSlot();
                formikRef.current.resetForm();
                setSelectedEvent({ date: null, filter: false, slot: null });
                setBookedEvent(selectedEvent);
                setIsLoading(false);
                setIsConfirmedRequest(true);
            }).catch(err => {
                setIsLoading(false);
                toastError(err.response?.data.message, 'top-right');
            })
    }

    const handleResetSlots = () => {
        setSelectedEvent(prev => ({ ...prev, slot: null }));
        handleNavigation("2", "right");
    }

    return (
        <Formik
            initialValues={{ name: value.name, email: value.email }}
            enableReinitialize={true}
            innerRef={formikRef}
            onSubmit={(values) => handleSaveEmail(values)}
            validationSchema={validations}>
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
                <section className='get-user-info-component'>
                    <div>
                        <div className="back-button" onClick={handleResetSlots}>
                            <img src={"https://app.wecal.co/assets/angle-left.svg"} alt="back_icon" />
                            <label className="calendar-header">Slots</label>
                        </div>
                        <h1 className="calendar-main-title mb-4">
                            {moment(selectedEvent.date).format("dddd DD MMMM YYYY")}
                        </h1>
                        <div className='slot-item-container' onClick={handleResetSlots}>
                            <div className="slot-item">
                                <div className='slot-icon icon-container'>
                                    <img src={"https://app.wecal.co/assets/angle-left.svg"} alt="timer_icon" />
                                </div>
                                <h6 className="slot-title mb-0">{new Date(selectedEvent.slot[0]).toLocaleTimeString('en', { timeStyle: 'short', hour12: false })} - {moment(new Date(selectedEvent.slot[0])).add(+publicCalendar?.duration, 'm').format('HH:mm')}</h6>
                            </div>
                        </div>

                        <FormGroup>
                            <Label className="lbl-email" for="email">Your Name</Label>
                            <Input
                                className='txt-email'
                                type="name" name="name"
                                id="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                placeholder="Enter Your Name" />
                            <p className='email-error text-danger mt-1'>{errors.name && touched.name && errors.name}</p>
                        </FormGroup>
                        <FormGroup>
                            <Label className="lbl-email" for="email">EMAIL</Label>
                            <Input
                                className='txt-email'
                                type="email"
                                name="email"
                                id="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                placeholder="Enter email" />
                            <p className='email-error text-danger mt-1'>{errors.email && touched.email && errors.email}</p>
                        </FormGroup>

                    </div>
                    <div className="public-page-save-container">
                        <button className='btn-save' onClick={handleSubmit} >
                            {isLoading ? <Spinner size="sm" color="primary" /> : 'Confirm Event'}
                        </button>
                    </div>
                </section >
            )}
        </Formik>
    )
}
