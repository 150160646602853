import React, { useState, useEffect } from "react";
import { images, toastError, toastSuccess } from "../../../utils/constants";
import BookingPageSettings from "../ShareWidget/components/BookingPageSettings";
import "./CalendarTheme.css";
import { Spinner } from "reactstrap";
import { updateCalendar } from "../../../apis/calendars";

export default function CalendarTheme({
  handleNavigation,
  selectedCalendar,
  isSmallScreen,
  isLoading: loading,
  setCalendarList,
  setSelectedCalendar,
  calendars,
}) {
  const [backgroundColor, setBackgroundColor] = useState(selectedCalendar?.backgroundColor || "#F2F3FF");
  const [textColor, setTextColor] = useState(selectedCalendar?.textColor || "#222");
  const [buttonLinkColor, setButtonLinkColor] = useState(selectedCalendar?.buttonLinkColor || "#e5e5ea");
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveCalendarTheme = () => {
    const themeColors = { backgroundColor, textColor, buttonLinkColor };
    setIsLoading(true);
    updateCalendar(selectedCalendar.id, themeColors)
      .then((res) => {
        const data = Object.assign([], calendars);
        const calendarIndex = data.findIndex(
          (cal) => cal.id === selectedCalendar.id
        );
        Object.assign(data[calendarIndex], themeColors);
        setCalendarList(data);
        setSelectedCalendar(data[calendarIndex]);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        setIsLoading(false);
      })
      .catch((err) => {
        toastError(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <div className="d-flex flex-column flex-grow-1">
          <div className="flex-grow-1">
            <div className="pb-3">
              <div
                className="back-button"
                onClick={() =>
                  handleNavigation(
                    "2",
                    "left",
                    `/calendar/${selectedCalendar.name}`
                  )
                }
              >
                <img src={images.backIcon} alt="icon" />
                <label className="calendar-header">Theme</label>
              </div>
              <p className="calendar-title">Calendar Theme</p>
            </div>
            <BookingPageSettings
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor}
              textColor={textColor}
              setTextColor={setTextColor}
              buttonLinkColor={buttonLinkColor}
              setButtonLinkColor={setButtonLinkColor}
            />
          </div>
          <button
            className="btn-save"
            onClick={() => handleSaveCalendarTheme()}
          >
            {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
          </button>
        </div>
      )}
    </>
  );
}
