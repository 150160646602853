import React, { useState, useEffect } from "react";
import "./PublicPageFreeSlots.css";
import moment from "moment";
import { Spinner } from "reactstrap";
import { getPublicMeetingPollSlots } from "../../../apis/calendars";
import { toastError } from "../../../utils/constants";
export default function MeetingPollFreeSlots({
  handleNavigation,
  selectedTimeZone,
  publicCalendar,
  meetingPollSlots,
  setMeetingPollSlots,
  isSmallScreen,
}) {
  const [slots, setSlots] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (publicCalendar?.id) {
      setLoading(true);
      getPublicMeetingPollSlots(publicCalendar.id)
        .then((res) => {
          setLoading(false);
          setSlots(res.data);
        })
        .catch((err) => {
          setLoading(false);
          toastError(err.response?.data?.message, "top-right");
        });
    }
  }, [publicCalendar]);

  const handleSelectMeetingPollSlots = (slot) => {
    const meetingPolls = meetingPollSlots.filter((s) => s.id !== slot.id);
    if (meetingPolls.length === meetingPollSlots.length) {
      setMeetingPollSlots((prev) => [...prev, slot]);
    } else {
      setMeetingPollSlots(meetingPolls);
    }
    if (!isSmallScreen) {
      handleNavigation(
        meetingPolls.length || meetingPolls.length === meetingPollSlots.length
          ? "2"
          : "1",
        "right"
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center bg-light p-2">
          <Spinner color="primary" />
        </div>
      ) : slots.length ? (
        <div className="flex-grow-1 d-flex flex-column justify-content-between">
          <div>
          <h1 className="calendar-main-title mb-3">Select Slots</h1>
          <div className="event-time-container mb-3 py-1 ps-3">
            {slots.map((slot, i) => {
              const isSelectedSlot = meetingPollSlots.find(
                (s) => s.id === slot.id
              );
              return (
                <div
                  key={slot.id}
                  onClick={() => handleSelectMeetingPollSlots(slot)}
                  className="event-time-item"
                >
                  <div
                    className={`event-time-title-container ${
                      slots.length - 1 === i ? "border-0" : ""
                    }`}
                  >
                    <div className="d-flex">
                      <input
                        className="form-check-input m-0 me-2"
                        type="checkbox"
                        value={!!isSelectedSlot}
                        checked={!!isSelectedSlot}
                      />
                      <h6 className="event-item-title mb-0">{`${moment(slot.from).format("dddd DD MMM YYYY HH:mm")} - ${moment(slot.to).format("HH:mm")}`}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
          {!!meetingPollSlots.length && isSmallScreen && (
            <div className="d-flex justify-content-center">
              <button
                className="btn-save"
                onClick={() => handleNavigation("2", "right")}
              >
               Continue
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <p>No slots are found</p>
        </div>
      )}
    </>
  );
}