import React, { useState, useRef } from "react";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import "./NewCalendar.css";
import { images, toastError } from "../../../utils/constants";
import { createCalender } from "../../../apis/calendars";

export default function NewCalendar({
  handleNavigation,
  setCalendarList,
  setSelectedCalendar,
  userLogin,
  setFilterCalendars,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null)

  const createCalendarHandler = (type) => {
    setIsLoading(true);
    createCalender({
      location: userLogin.defaultLocation,
      duration: 30,
      type,
      userId: userLogin.id,
      availabilityId: 1,
    })
      .then(({ data }) => {
        setIsLoading(false);
        setCalendarList((prev) => [...prev, data]);
        setFilterCalendars((prev) => [...prev, data]);
        setSelectedCalendar(data);
        handleNavigation("2", "", `/calendar/${data.name}`);
      })
      .catch((err) => {
        setIsLoading(false);
        toastError(err.response?.data?.message, "top-right");
      });
  };

  return (
    <>
      <div className="calendar-main-title-container">
        <div
          onClick={() => handleNavigation("1", "right", "/")}
          className="back-button"
        >
          <img src={images.backIcon} alt="icon" />
          <label className="calendar-header">Home</label>
        </div>
        <h1 className="calendar-main-title">New Calendar</h1>
      </div>
      <div className="new-calendar-container mb-3 py-1 ps-3">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <div
              onClick={() => createCalendarHandler("individual")}
              className="new-calendar-item"
            >
              <div className="new-calendar-icon icon-container">
                <img src={images.calendarIcon} alt=".." />
              </div>
              <div className="new-calendar-title">
                <h6 className="new-calendar-item-title mb-0">
                  Individual calendar
                </h6>
                <div className="direction-right pe-3">
                  <img
                    src={images.addIcon}
                    alt="angle_right"
                    className="add-calendar-icon"
                  />
                </div>
              </div>
            </div>
            <div className="new-calendar-item">
              <div className="new-calendar-icon icon-container">
                <img src={images.collectiveIcon} alt="icon" />
              </div>
              <div
                onClick={() => createCalendarHandler("individual")}
                className="new-calendar-title"
              >
                <h6 className="new-calendar-item-title mb-0 me-1">
                  Collective calendar
                </h6>
                <div className="direction-right pe-3">
                  <img
                    src={images.addIcon}
                    alt="angle_right"
                    className="add-calendar-icon"
                    id="collectiveCalendar"
                  />
                </div>
              </div>
            </div>
            <div className="new-calendar-item">
              <div className="new-calendar-icon icon-container">
                <img src={images.roundMeetingIcon} alt=".." />
              </div>
              <div
                onClick={() => createCalendarHandler("round-robin")}
                className="new-calendar-title"
              >
                <div className="d-flex align-items-center">
                  <h6 className="new-calendar-item-title mb-0 me-1">
                    Round-robin meeting
                  </h6>
                  <img
                    src={images.infoIcon}
                    alt="info_icon"
                    ref={ref}
                  />
                    <UncontrolledTooltip  
                     className="meeting-info-tooltip"
                     placement="bottom"
                    target={ref}>
                    For sales and customer success times. Shows times when one
                    person in the team is available.
                    </UncontrolledTooltip>
                </div>
                <div className="direction-right pe-3">
                  <img
                    src={images.addIcon}
                    alt="angle_right"
                    className="add-calendar-icon"
                    id="collectiveCalendar"
                  />
                </div>
              </div>
            </div>
            <div className="new-calendar-item">
              <div className="new-calendar-icon icon-container">
                <img src={images.meetingIcon} alt="icon" />
              </div>
              <div
                onClick={() => createCalendarHandler("meeting-poll")}
                className="new-calendar-title border-0"
              >
                <h6 className="new-calendar-item-title mb-0 me-1">
                Meeting poll
                </h6>
                <div className="direction-right pe-3">
                  <img
                    src={images.addIcon}
                    alt="angle_right"
                    className="add-calendar-icon"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

    </>
  );
}
