import React, { useState } from "react";
import ColorPicker from "./../../../ColorPicker/ColorPicker";
import { FormGroup, Input, Spinner, Tooltip } from "reactstrap";
import "./styles.css";
export default function LinkWidget({
  calendarWidgetURL,
  toggle,
  copyToClipBoard,
  copySuccess,
  backButtonSwitchHandler,
  isLoading,
  selectedCalendar,
  handleSaveCalendarTheme
}) {
  const defaultLinkColor = selectedCalendar?.linkTextColor || "#00e";
  const defaultLinkText = selectedCalendar?.linkText|| "Schedule time with me";
  const [linkTextColorPicker, setLinkTextColorPicker] = useState(false);
  const [linkText, setLinkText] = useState(defaultLinkText);
  const [linkTextColor, setLinkTextColor] = useState(defaultLinkColor);
  const popupText = `<link href="http://localhost:3000/widget/style.css" rel="stylesheet"> 
    <script type="text/javascript" src="http://localhost:3000/widget/script.js" async></script>
    <a href="#" style="color:${defaultLinkColor};" 
    onclick="return openWeCalWidget('${calendarWidgetURL}',event);">${defaultLinkText}</a>`;

    
  return (
    <>
      <div>
        <h2 className="calendar-title pb-3">Popup Text</h2>
        <p>
          Place this code in your HTML where you want your scheduling link to
          appear. When a visitor clicks the link, your Calendly widget will load
          as a popup.
        </p>
        <FormGroup>
          <Input
            className="widget-description-textarea form-control"
            name="text"
            type="textarea"
            value={popupText}
          />
        </FormGroup>

        <div className="mb-3">
        <h6 className='meeting-label'>Link - Text</h6>
          <Input
            className="txt-calendar-name"
            name="text"
            type="text"
            onChange={({ target }) => setLinkText(target.value)}
            value={linkText}
          />
        </div>

        <ColorPicker
          label={"Link - Text Color"}
          setColor={setLinkTextColor}
          color={linkTextColor}
          setOpen={setLinkTextColorPicker}
          isOpen={linkTextColorPicker}
        />
      </div>
      {toggle && (
        <div className="time-slot-button-container">
          <button className="btn-cancel" onClick={backButtonSwitchHandler}>
            Back
          </button>
          <Tooltip isOpen={copySuccess} target="TooltipExample">
            Copied!
          </Tooltip>
          <button
            className="btn-save"
            id="TooltipExample"
            onClick={() => copyToClipBoard(popupText)}
          >
            {copySuccess ? "Copied!" : "Copy Code"}
          </button>
          <button
            className="btn-save"
            onClick={() => handleSaveCalendarTheme({
              linkTextColor,
              linkText
            })}
          >
           {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
          </button>
        </div>
      )}
    </>
  );
}
