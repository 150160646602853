import React, { useEffect, useState } from 'react'
import "./HomePage.css"
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Slide from 'react-reveal/Slide';
import { getCalendars } from "../../apis/calendars";
import { getAvailability, getElements, getTeam } from "../../apis/element";
import { toastError } from '../../utils/constants';
import { CLIENT_ID, SCOPES } from "../../calendarConfig";
import { useGoogleLogin } from 'react-google-login';
import { authenticateWithGoogle } from "../../apis/auth";
import SplashScreen from '../../Components/SplashScreen/SplashScreen';
import HomeSidebar from '../../Components/Home/HomeSidebar/HomeSidebar';
import UserDetails from '../../Components/Home/UserDetails/UserDetails';
import UserLink from '../../Components/Home/UserLink/UserLink';
import Support from '../../Components/Home/Support/Support';
import NewCalendar from "../../Components/Home/NewCalendar/NewCalendar";
import NewElement from "../../Components/Home/NewElement/NewElement";
import SelectCalendarComponent from "../../Components/Home/SelectCalendar/SelectCalendarComponent"
import SelectAvailabilityComponent from '../../Components/Home/SelectAvailability/SelectAvailabilityComponent';
import CalendarDetails from "../../Components/Home/CalendarDetails/CalendarDetails";
import AvailabilityDetails from '../../Components/Home/AvailabilityDetails/AvailabilityDetails';
import SelectVideoMeeting from '../../Components/Home/VideoMeeting/SelectVideoMeeting';
import AddTimeSlots from '../../Components/Home/AddTimeSlots/AddTimeSlots';
import SelectTeam from '../../Components/Home/SelectTeam/SelectTeam';
import TeamDetails from '../../Components/Home/TeamDetails/TeamDetails';
import Participants from '../../Components/Home/Participants/Participants';
import { Spinner } from "reactstrap";
import Location from "../../Components/Home/Location/Location";
import LocationDetails from "../../Components/Home/LocationDetails/LocationDetails";
import DefaultLocation from '../../Components/Home/DefaultLocation/DefaultLocation';
import ShareWidget from '../../Components/Home/ShareWidget/ShareWidget';
import ReConnectModal from "../../Components/ReConnectModal/ReConnectModal";
import CalendarTheme from '../../Components/Home/CalendarTheme/CalendarTheme';
import PollResults from '../../Components/Home/PollResults/PollResults';
import CalendarDetailsMeetingPoll from '../../Components/Home/CalendarDetails/CalendarDetailsMeetingPoll';
import Placeholder from '../../Components/Home/Placeholder/Placeholder';

export default function Home({ setIsRefreshTokenExpired,isRefreshTokenExpired,handleWebNavigation, handleNavigation, showPanel, slideDirection, userLogin, setUserLogin, isSmallScreen }) {
  const [calendars, setCalendars] = useState([]);
  const [filterCalendars, setFilterCalendars] = useState([]);
  const [elements, setElements] = useState([]);
  const [filterElements, setFilterElements] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [isLoadingElement, setIsLoadingElement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedCalendarLoading, setIsSelectedCalendarLoading] = useState(false);
  const [isSelectedElementLoading, setIsSelectedElementLoading] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true);
  const [createdTeam, setCreatedTeam] = useState(false);
  const [isDeleteTeam, setIsDeleteTeam] = useState(false);
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [zoomAccessCode,] = useState(queryParams.get("code"));
  const history = useHistory();
  const calendarLink = userLogin ? "/" + userLogin.link : "";
  const [zoomEnabled, setZoomEnabled] = useState(false);
  useEffect(() => {
    if (userLogin) {
      setZoomEnabled(userLogin.zoomEnabled);
      getCalendarsHandler();
      getElementsHandler();
    }
  }, [isUserLoading])

  
  useEffect(() => {
    handleWebNavigation(pathname);
    const unlisten = history.listen((e) => {
      handleWebNavigation(e.pathname);
    });
    return () => unlisten();
  }, [isSmallScreen]);

  const handleSignIn = ({ code, accessToken }) => {
    setIsUserLoading(true);
    authenticateWithGoogle({ code, accessToken, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(({ user }) => {
        setUserLogin(user)
        localStorage.setItem('user', JSON.stringify(user));
        setIsUserLoading(false);
      }).catch(err => {
        console.log(err);
        localStorage.clear();
        setIsUserLoading(false);
        toastError(err, "top-right");
      })
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('user');
    if (accessToken && !isUserLoading && !isSignedIn) {
      // handleSignIn({ accessToken });
    }
  }, [isUserLoading, isSignedIn]);

  useGoogleLogin({
    clientId: CLIENT_ID,
    isSignedIn: true,
    responseType: "code",
    prompt:'consent',
    accessType:'offline',
    // responseType: "accessToken",
    scope: SCOPES,
    onSuccess: handleSignIn, onFailure: (err) => toastError(err, "top-right"),
    onAutoLoadFinished: (res) => {
      setIsSignedIn(res);
      setIsUserLoading(res);
    }
  })

  const getCalendarsHandler = () => {
    setIsLoadingCalendar(true);
    getCalendars()
      .then(({ data }) => {
        setIsLoadingCalendar(false);
        if (data && data.length) {
          setCalendars(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
          setFilterCalendars(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
          const splitPath = pathname.split("/");
          if ((splitPath[1] === "calendar" || pathname === "/" || splitPath[1] === 'seturl') && !zoomAccessCode) {
            const calendar = data.find(cal => cal.name === splitPath[2]);
            setSelectedCalendar(calendar ? calendar : data[0]);
            const initialPath = !calendar ? `/calendar/${data[0].name}` : pathname;
            handleNavigation("1", "right", initialPath);
          }
        }
      }).catch(err => {
        setIsLoadingCalendar(false);
        toastError(err.response?.data?.errors, "bottom-right");
      });
  }


  const handleSelectTeam = async (id, payload, isCreateTeam, isDelete) => {
    try {
      setIsSelectedElementLoading(true);
      const { data } = await getTeam(id, payload);
      setSelectedTeam(data);
      setSelectedElement(data);
      setCreatedTeam(isCreateTeam);
      setIsDeleteTeam(isDelete);
      setIsSelectedElementLoading(false);
      const panel = isDelete && isSmallScreen ? "1" : isSmallScreen ? "2" : "3";
      const path = isSmallScreen ? "/team" : "/team/teamdetails";
      const slideDirection = isSmallScreen ? "right" : "left";
      handleNavigation(panel, slideDirection, path);
    } catch (err) {
      setIsSelectedElementLoading(false);
      toastError(err.response?.data?.errors, "bottom-right");
    }
  }

  const selectAvailabilityHandler = async (id) => {
    try {
      setIsSelectedElementLoading(true);
      const { data } = await getAvailability(id, Intl.DateTimeFormat().resolvedOptions().timeZone);
      setSelectedAvailability(data);
      setIsSelectedElementLoading(false);
      isSmallScreen ? handleNavigation("1", "right", "/") : handleNavigation("3", "left", "/availability/timeslots")
    }
    catch (err) {
      setIsSelectedElementLoading(false);
      toastError(err.response?.data?.message, "top-right");
    }

  }

  const getElementsHandler = (selectedElementId, type, isCreateTeam = false, isDelete = false) => {
    setIsLoadingElement(true);
    getElements()
      .then(async ({ data }) => {
        const path = pathname.split("/");
        let selectElement = data.length && data.find(elm => elm.id === selectedElementId);
        data = data.filter(p => p.name !== 'Just Me');
        setSelectedElement(selectElement);
        setElements(data);
        setFilterElements(data);
        setIsLoadingElement(false);
        if (!zoomAccessCode) {
          if (path[1] === "team" || type === "team") {
            if (!selectElement) selectElement = data.find(elm => elm.type === "team");
            if (selectElement) await handleSelectTeam(selectElement.id, { name: selectElement.name }, isCreateTeam, isDelete);
          } else if (path[1] === "availability" || type === "availability") {
            if (!selectElement) selectElement = data.find(a => a.name === 'Default Availability');
            if (selectElement) await selectAvailabilityHandler(selectElement.id);
          } else if (path[1] === "elementdetails" || type === "video-meeting" || type === "integrations") {
            if (!selectElement) selectElement = data.find(a => a.type === "video-meeting" ||  a.type === "integrations");
            if (selectElement) {
              handleNavigation("2", "", "/elementdetails");
              setSelectedElement(selectElement);
              setIsSelectedElementLoading(false);
            }
          } else if (path[1] === "location" || type === "location") {
            if (!selectElement) selectElement = data.find(a => a.type === "location");
            if (selectElement) {
              handleNavigation("2", "", "/location/locationdetails");
              setIsSelectedElementLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      }).catch(err => {
        setIsLoading(false);
        setIsLoadingElement(false);
        toastError(err.response?.data?.errors, "bottom-right");
      });
  }

  const searchCalendarChangeHandler = (search) => {
    if (!search) {
      setFilterCalendars(calendars);
      setFilterElements(elements);
      return
    }
    let searchCalendars = calendars.filter((calendar) => {
      return calendar.name.toLowerCase().includes(search.toLowerCase()) || (calendar?.description && calendar?.description.toLowerCase().includes(search.toLowerCase()));
    });
    let searchElements = elements.filter((element) => {
      return element.name.toLowerCase().includes(search.toLowerCase());
    });
    setFilterCalendars(searchCalendars);
    setFilterElements(searchElements);
  }

  if (!localStorage.getItem('token') && !userLogin && !zoomAccessCode && !isUserLoading) {
    return <Redirect from="/" to="/signin" />
  }else if (userLogin && (!userLogin.link || !userLogin.defaultLocation)){
    return <Redirect from="/" to="/seturl" />
  }

  return (
    isUserLoading ? <SplashScreen /> :
      <>
        <ReConnectModal setIsRefreshTokenExpired={setIsRefreshTokenExpired} isRefreshTokenExpired={isRefreshTokenExpired} setUserLogin={setUserLogin} userLogin={userLogin} />
        <section className="home-container">
          <Slide left duration={200} when={!isSmallScreen || showPanel === "1"}>
            <section className={`border-end panel custom-col-1 d-lg-flex ${showPanel === "1" ? "d-flex" : "d-none"}`}>
              <HomeSidebar
                calendars={filterCalendars}
                setIsSelectedElementLoading={setIsSelectedElementLoading}
                userLogin={userLogin}
                setSelectedCalendar={(data) => setSelectedCalendar(data)}
                elements={filterElements}
                handleNavigation={handleNavigation}
                isLoadingCalendar={isLoadingCalendar}
                isLoadingElement={isLoadingElement}
                setSelectedAvailability={setSelectedAvailability}
                setSelectedElement={setSelectedElement}
                searchCalendar={searchCalendarChangeHandler}
                setSelectedTeam={setSelectedTeam}
                handleSelectTeam={handleSelectTeam}
                selectedCalendar={selectedCalendar}
                selectedElement={selectedElement}
                isSmallScreen={isSmallScreen}
                setIsSelectedCalendarLoading={setIsSelectedCalendarLoading}
              />
            </section>
          </Slide>

          {isLoading || isLoadingElement || isSelectedCalendarLoading || isSelectedElementLoading ?
            <div className='d-flex align-items-center justify-content-center flex-grow-1'>
              <Spinner color="primary" />
            </div> :
            <>
              <Slide when={!isSmallScreen || showPanel === "2"}
                right={slideDirection === "right"} left={slideDirection === "left"} duration={200}>
                <section className={`border-end panel custom-col-2 d-lg-flex ${showPanel === "2" ? "d-flex" : "d-none"}`}>
                  <Switch>
                    <Route path="/profiledetails" render={(props) =>
                      <UserDetails {...props}
                        handleNavigation={handleNavigation}
                        setUserLogin={setUserLogin}
                        userLogin={userLogin} />} />

                    <Route path="/calendar/:calendarName" component={(props) =>
                      <SelectCalendarComponent {...props}
                        isSmallScreen={isSmallScreen}
                        selectedCalendar={selectedCalendar}
                        setSelectedCalendar={setSelectedCalendar}
                        handleNavigation={handleNavigation}
                        calendarList={calendars}
                        setCalendarList={setCalendars}
                        calendarLink={calendarLink}
                        getCalendarsHandler={getCalendarsHandler}
                      />}
                    />
                    <Route path="/availability" component={(props) =>
                      <SelectAvailabilityComponent {...props}
                        handleNavigation={handleNavigation}
                        selectedAvailability={selectedAvailability}
                        getElementsHandler={getElementsHandler}
                        isSmallScreen={isSmallScreen}
                      />}
                    />
                    <Route path="/elementdetails" exact render={(props) =>
                      <SelectVideoMeeting  {...props}
                        handleNavigation={handleNavigation}
                        selectedElement={selectedElement}
                        showPanel={showPanel}
                        zoomEnabled={zoomEnabled}
                        setZoomEnabled={setZoomEnabled}
                        zoomAccessCode={zoomAccessCode}
                      />} />
                    <Route path="/location" render={(props) =>
                      <Location  {...props}
                        handleNavigation={handleNavigation}
                        getElementsHandler={getElementsHandler}
                        selectedElement={selectedElement}
                        showPanel={showPanel}
                      />} />
                    <Route path="/team" render={(props) =>
                      <SelectTeam  {...props}
                        handleNavigation={handleNavigation}
                        selectedTeam={selectedTeam}
                        showPanel={showPanel}
                        getElementsHandler={getElementsHandler}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/newcalendar" exact render={(props) =>
                      <NewCalendar {...props}
                        userLogin={userLogin}
                        setCalendarList={setCalendars}
                        setFilterCalendars={setFilterCalendars}
                        selectedCalendar={selectedCalendar}
                        setSelectedCalendar={(data) => setSelectedCalendar(data)}
                        handleNavigation={handleNavigation}
                      />} />

                    <Route path="/newelement" render={(props) =>
                      <NewElement {...props}
                        handleNavigation={handleNavigation}
                        setSelectedAvailability={setSelectedAvailability}
                        setSelectedElement={setSelectedElement}
                        setElements={setElements}
                        elements={elements}
                        setFilterElements={setFilterElements}
                        setSelectedTeam={setSelectedTeam}
                        userLogin={userLogin}
                        getElementsHandler={getElementsHandler}
                        isSmallScreen={isSmallScreen}
                      />} />
                  </Switch>
                </section>
              </Slide>

              <Slide when={!isSmallScreen || showPanel === "3"} right duration={200} >
                <section className={`panel custom-col-3 d-lg-flex ${showPanel === "3" ? "d-flex" : "d-none"}`}>
                  <Switch>
                    <Route path="/profiledetails/support" render={(props) =>
                      <Support {...props}
                        handleNavigation={handleNavigation}
                        isSmallScreen={isSmallScreen} />} />

                    <Route path="/profiledetails" exact render={(props) =>
                      <UserLink {...props}
                        isSmallScreen={isSmallScreen}
                        handleNavigation={handleNavigation}
                        setUserLogin={setUserLogin}
                        userLogin={userLogin} />} />

                    {selectedCalendar?.type === 'meeting-poll' ? 
                    <Route exact path="/calendar/:calendarName" render={(props) =>
                      <CalendarDetailsMeetingPoll  {...props}
                        setCalendarList={setCalendars}
                        setSelectedCalendar={setSelectedCalendar}
                        selectedCalendar={selectedCalendar}
                        calendars={calendars}
                        handleNavigation={handleNavigation}
                        calendarLink={calendarLink}
                        isSmallScreen={isSmallScreen}
                        zoomEnabled={zoomEnabled}
                      />} /> : 
                      <Route exact path="/calendar/:calendarName" render={(props) =>
                        <CalendarDetails  {...props}
                          setCalendarList={setCalendars}
                          setSelectedCalendar={setSelectedCalendar}
                          selectedCalendar={selectedCalendar}
                          calendars={calendars}
                          handleNavigation={handleNavigation}
                          calendarLink={calendarLink}
                          isSmallScreen={isSmallScreen}
                          zoomEnabled={zoomEnabled}
                        />} />
                      }

                    <Route path="/calendar/:calendarName/availabilitydetails" exact render={(props) =>
                      <AvailabilityDetails  {...props}
                        getElementsHandler={getElementsHandler}
                        handleNavigation={handleNavigation}
                        selectedCalendar={selectedCalendar}
                        selectedAvailability={selectedAvailability}
                        setSelectedAvailability={setSelectedAvailability}
                        setSelectedCalendar={setSelectedCalendar}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/calendar/:calendarName/share" exact render={(props) =>
                      <ShareWidget  {...props}
                        handleNavigation={handleNavigation}
                        selectedCalendar={selectedCalendar}
                        userLogin={userLogin}
                        setCalendarList={setCalendars}
                        setSelectedCalendar={setSelectedCalendar}
                        calendars={calendars}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/calendar/:calendarName/theme" exact render={(props) =>
                      <CalendarTheme  {...props}
                        setCalendarList={setCalendars}
                        setSelectedCalendar={setSelectedCalendar}
                        selectedCalendar={selectedCalendar}
                        calendars={calendars}
                        handleNavigation={handleNavigation}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/calendar/:calendarName/results" exact render={(props) =>
                      <PollResults  {...props}
                        handleNavigation={handleNavigation}
                        selectedCalendar={selectedCalendar}
                      />} />
                    <Route path="/calendar/:calendarName/placeholder" exact render={(props) =>
                      <Placeholder  {...props}
                        handleNavigation={handleNavigation}
                        selectedCalendar={selectedCalendar}
                      />} />

                    <Route path="/calendar/:calendarName/participants" exact render={(props) =>
                      <Participants  {...props}
                        handleNavigation={handleNavigation}
                        selectedCalendar={selectedCalendar}
                        userLogin={userLogin}
                        getElementsHandler={getElementsHandler}
                        setSelectedCalendar={setSelectedCalendar}
                        selectedTeam={selectedTeam}
                        createdTeam={createdTeam}
                        setCreatedTeam={setCreatedTeam}
                        isDeleteTeam={isDeleteTeam}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/availability/timeslots" exact render={() =>
                      <AddTimeSlots handleNavigation={handleNavigation}
                        selectedAvailability={selectedAvailability}
                        setSelectedAvailability={setSelectedAvailability}
                        setSelectedElement={setSelectedElement}
                        setIsLoadingElement={setIsLoadingElement}
                        getElementsHandler={getElementsHandler}
                        isSmallScreen={isSmallScreen}
                      />} />

                    <Route path="/team/teamdetails" exact render={() =>
                      <TeamDetails
                        handleNavigation={handleNavigation}
                        getElementsHandler={getElementsHandler}
                        selectedElement={selectedElement}
                        isSmallScreen={isSmallScreen}
                        selectedTeam={selectedTeam}
                      />} />
                    <Route path="/location/locationdetails" exact render={() =>
                      <LocationDetails
                        handleNavigation={handleNavigation}
                        getElementsHandler={getElementsHandler}
                        selectedElement={selectedElement}
                        isSmallScreen={isSmallScreen}
                      />} />
                    <Route path="/profiledetails/defaultLocation" exact render={() =>
                      <DefaultLocation
                        handleNavigation={handleNavigation}
                        setUserLogin={setUserLogin}
                        userLogin={userLogin}
                        showPanel={showPanel}
                      />} />
                    <Route path="/newelement/elementdetails" exact render={(props) =>
                      <SelectVideoMeeting  {...props}
                        handleNavigation={handleNavigation}
                        selectedElement={selectedElement}
                        showPanel={showPanel}
                      />} />
                  </Switch>
                </section>
              </Slide>
            </>
          }
        </section>
      </>
  )
}
