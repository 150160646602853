import React, { useState } from "react";
import ColorPicker from "./../../../ColorPicker/ColorPicker";

export default function BookingPageSettings({
  backgroundColor,
  setBackgroundColor,
  textColor,
  setTextColor,
  buttonLinkColor,
  setButtonLinkColor,
}) {
  const [isOpenBackgroundColor, setIsOpenBackgroundColor] =useState(false);
  const [isOpenTextColor, setIsOpenTextColor] = useState(false);
  const [isOpenButtonLinkColor, setIsOpenButtonLinkColor] = useState(false);
  return (
    <>
      <ColorPicker
        label={"Booking page - Background Color"}
        setColor={setBackgroundColor}
        color={backgroundColor}
        setOpen={setIsOpenBackgroundColor}
        isOpen={isOpenBackgroundColor}
      />
      <ColorPicker
        label={"Booking page - Text Color"}
        setColor={setTextColor}
        color={textColor}
        setOpen={setIsOpenTextColor}
        isOpen={isOpenTextColor}
      />
      <ColorPicker
        label={"Booking page - Button & Link Color"}
        setColor={setButtonLinkColor}
        color={buttonLinkColor}
        setOpen={setIsOpenButtonLinkColor}
        isOpen={isOpenButtonLinkColor}
      />
    </>
  );
}
