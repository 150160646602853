import React from 'react'
import "./Colorpicker.css"
import { SketchPicker } from 'react-color';
import { DropdownToggle, Dropdown, DropdownMenu } from 'reactstrap';
export default function ColorPicker({label,setColor,color,isOpen,setOpen}) {
  return (
    <div className=' justify-content-between align-items-center py-2'>
            <h6 className='meeting-label'>{label}</h6>
            <Dropdown isOpen={isOpen} 
            toggle={() => setOpen(!isOpen)} 
             >
            <DropdownToggle caret className='btn-light d-inline-flex align-items-center color-box-container'>
            <div className='color-box' style={{backgroundColor:color}} />
            </DropdownToggle>
            <DropdownMenu>
            <SketchPicker color={color} disableAlpha={true} onChangeComplete={(color) => setColor(color.hex)} />
            </DropdownMenu>
            </Dropdown>
    </div>
  )
}
