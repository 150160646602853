import React, { useRef, useState } from "react";
import { images, toastError, toastSuccess } from "../../../utils/constants";
import "./ShareWidget.css";
import PopupWidget from "./components/PopupWidget";
import LinkWidget from "./components/LinkWidget";
import InlineWidget from "./components/InlineWidget";
import { updateCalendar } from "../../../apis/calendars";
export default function ShareWidget({
  handleNavigation,
  selectedCalendar,
  userLogin,
  isSmallScreen,
  isLoading: loading,
  setCalendarList,
  setSelectedCalendar,
  calendars,
}) {
  const [toggle, setToggle] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calendarWidgetURL = `http://localhost:3000/public/${userLogin.link}/${selectedCalendar?.name}`;

  const widgetSelectHandler = (e) => setToggle(e.target.value);
  const backButtonSwitchHandler = () => setToggle("");

  const copyToClipBoard = async (widget) => {
    try {
      await navigator.clipboard.writeText(widget);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 5000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const handleSaveCalendarTheme = (widgetSettings) => {
    setIsLoading(true);
    updateCalendar(selectedCalendar.id, widgetSettings)
      .then((res) => {
        const data = Object.assign([], calendars);
        const calendarIndex = data.findIndex(
          (cal) => cal.id === selectedCalendar.id
        );
        Object.assign(data[calendarIndex], widgetSettings);
        setCalendarList(data);
        console.log(widgetSettings,"call")
        setSelectedCalendar(data[calendarIndex]);
        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        setIsLoading(false);
      })
      .catch((err) => {
        toastError(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div>
      {!toggle ? (
        <>
          <div className="pb-4">
            <div
              className="back-button"
              onClick={() =>
                handleNavigation(
                  "2",
                  "left",
                  `/calendar/${selectedCalendar.name}`
                )
              }
            >
              <img src={images.backIcon} alt="icon" />
              <label className="calendar-header">Share</label>
            </div>
            <p className="calendar-title">
              How do you want to add WeCal to your site?
            </p>
          </div>
          <div className="switch-field">
            <div className="py-2 d-flex justify-content-center">
              <input
                type="radio"
                id="inlineEmbed"
                name="switchToggle"
                value="inlineEmbed"
                onChange={widgetSelectHandler}
                checked={toggle === "inlineEmbed"}
              />
              <label htmlFor="inlineEmbed">
                <div className="radio-btn-container">
                  <p className="radio-btn-heading">Inline Embed</p>
                  <p className="radio-btn-subText">
                    Add a scheduling page to your site
                  </p>
                </div>
              </label>
            </div>
            <div className="py-2 d-flex justify-content-center">
              <input
                type="radio"
                id="popupWidget"
                name="switchToggle"
                value="popupWidget"
                onChange={widgetSelectHandler}
                checked={toggle === "popupWidget"}
              />
              <label htmlFor="popupWidget">
                {/* <img src="https://assets.calendly.com/packs/frontend/media/popup-widget-f7ff49530981c7b3cc10.svg" alt="" className='radio-btn-img float-start' /> */}
                <div className="radio-btn-container">
                  <p className="radio-btn-heading">Popup Widget</p>
                  <p className="radio-btn-subText">
                    Add a floating button that opens a popup
                  </p>
                </div>
              </label>
            </div>
            <div className="py-2 d-flex justify-content-center">
              <input
                type="radio"
                id="popupText"
                name="switchToggle"
                value="popupText"
                onChange={widgetSelectHandler}
                checked={toggle === "popupText"}
              />
              <label htmlFor="popupText">
                {/* <img src="https://assets.calendly.com/packs/frontend/media/popup-text-8c20b93872dbc9d01fc3.svg" alt="" className='radio-btn-img float-start' /> */}
                <div className="radio-btn-container">
                  <p className="radio-btn-heading">Popup Text</p>
                  <p className="radio-btn-subText">
                    Add a text link that opens a popup
                  </p>
                </div>
              </label>
            </div>
          </div>
        </>
      ) : 
      <div className="d-flex justify-content-between flex-column time-slot-container">
        {toggle === "inlineEmbed" && (
          <InlineWidget
            calendarWidgetURL={calendarWidgetURL}
            toggle={toggle}
            copyToClipBoard={copyToClipBoard}
            copySuccess={copySuccess}
            backButtonSwitchHandler={backButtonSwitchHandler}
          />
        )}
        {toggle === "popupWidget" && (
          <PopupWidget
            calendarWidgetURL={calendarWidgetURL}
            toggle={toggle}
            copyToClipBoard={copyToClipBoard}
            copySuccess={copySuccess}
            backButtonSwitchHandler={backButtonSwitchHandler}
            handleSaveCalendarTheme={handleSaveCalendarTheme}
            selectedCalendar={selectedCalendar}
            isLoading={isLoading}
          />
        )}
        {toggle === "popupText" && (
          <LinkWidget
            calendarWidgetURL={calendarWidgetURL}
            toggle={toggle}
            copyToClipBoard={copyToClipBoard}
            copySuccess={copySuccess}
            backButtonSwitchHandler={backButtonSwitchHandler}
            handleSaveCalendarTheme={handleSaveCalendarTheme}
            selectedCalendar={selectedCalendar}
            isLoading={isLoading}
          />
        )}
      </div>
      }
    </div>
  );
}
