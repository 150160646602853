import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import './Assets/Fonts/Roboto/Roboto-Bold.ttf';
import './Assets/Fonts/Roboto/Roboto-Regular.ttf';
import './Assets/Fonts/Roboto/Roboto-Medium.ttf';
import { BrowserTracing } from '@sentry/tracing';
import "../node_modules/react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import register from './registerServiceWorker';
import "bootstrap/dist/css/bootstrap.min.css";
Sentry.init({
  dsn: "https://8ebc8770d5684e45bc372f712671f3dd@o4504065307508736.ingest.sentry.io/4504082668781568",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById('root')
);



register();
reportWebVitals();
