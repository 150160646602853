import React, { useState } from "react";
import ColorPicker from "../../../ColorPicker/ColorPicker";
import { FormGroup, Input, Spinner, Tooltip } from "reactstrap";
export default function PopupWidget({
  calendarWidgetURL,
  toggle,
  copyToClipBoard,
  copySuccess,
  backButtonSwitchHandler,
  handleSaveCalendarTheme,
  isLoading,
  selectedCalendar,
}) {
  const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] = useState(false);
  const [displayTextColorPicker, setDisplayTextColorPicker] = useState(false);

  const defaultBackgroundColor = selectedCalendar?.floatingButtonBackgroundColor || "rgb(0, 105, 255)";
  const defaultTextColor = selectedCalendar?.floatingButtonTextColor || "#fff";
  const defaultButtonText = selectedCalendar?.floatingButtonText || "Schedule time with me";
  
  const [floatingButtonBackgroundColor, setFloatingButtonBackgroundColor] = useState(defaultBackgroundColor);
  const [floatingButtonTextColor, setFloatingButtonTextColor] = useState(defaultTextColor);
  const [floatingButtonText,setFloatingButtonText] = useState(defaultButtonText);

  const popupWidget = `<link href="http://localhost:3000/widget/style.css" rel="stylesheet"> 
    <script type="text/javascript" src="http://localhost:3000/widget/script.js" async></script>
    <div style="color:${defaultTextColor}; background-color:${defaultBackgroundColor};"
    onclick="openWeCalWidget('${calendarWidgetURL}')" class="wecal-badge-button">${defaultButtonText}</div>`;

  return (
    <>
      <div>
        <h2 className="calendar-title pb-3">Popup Widget</h2>
        <p>
          Place this code in your HTML on the pages you want your floating
          button to appear. When a visitor clicks the button, your Calendly
          widget will load as a popup.
        </p>
        <FormGroup>
          <Input
            className="widget-description-textarea form-control"
            id="exampleText"
            name="text"
            type="textarea"
            value={popupWidget}
          />
        </FormGroup>
        <div className="mb-3">
          <h2 className="meeting-label">Floating Button - Text</h2>
          <Input
            className="txt-calendar-name"
            id="exampleText"
            name="text"
            type="text"
            onChange={({ target }) => setFloatingButtonText(target.value)}
            value={floatingButtonText}
          />
        </div>
        <ColorPicker
          label={"Floating Button - Background Color"}
          setColor={setFloatingButtonBackgroundColor}
          color={floatingButtonBackgroundColor}
          setOpen={setDisplayBackgroundColorPicker}
          isOpen={displayBackgroundColorPicker}
        />
        <ColorPicker
          label={"Floating Button - Text Color"}
          setColor={setFloatingButtonTextColor}
          color={floatingButtonTextColor}
          setOpen={setDisplayTextColorPicker}
          isOpen={displayTextColorPicker}
        />
      </div>
      {toggle && (
        <div className="time-slot-button-container">
          <button className="btn-cancel" onClick={backButtonSwitchHandler}>
            Back
          </button>
          <Tooltip isOpen={copySuccess} target="TooltipExample">
            Copied!
          </Tooltip>
          <button
            className="btn-save"
            id="TooltipExample"
            onClick={() => copyToClipBoard(popupWidget)}
          >
            {copySuccess ? "Copied!" : "Copy Code"}
          </button>
          <button
            className="btn-save"
            onClick={() =>
              handleSaveCalendarTheme({
                floatingButtonBackgroundColor,
                floatingButtonText,
                floatingButtonTextColor,
              })
            }
          >
            {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
          </button>
        </div>
      )}
    </>
  );
}
